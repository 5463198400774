@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700&display=swap');

*{margin:0;padding:0;}
body{font-family: 'Heebo', sans-serif;background:#fff;color:#1f1f1f;font-size:15px;letter-spacing:0.2px;overflow-x: hidden;}
ul{list-style:none;margin:0;}
p{line-height:26px;color:#1f1f1f;margin-bottom:1em;}
img{max-width:100%;height: auto}
code, kbd, pre, samp, blockquote {font-family: 'Heebo', sans-serif}
h1,h2,h3,h4,h5,h6{font-weight:600;margin:0 0 1em;letter-spacing:1px;color: #272727}
h1{font-size:35px;}
h2{font-size:30px;}
h3{font-size:26px;}
h4{font-size:22px;}
h5{font-size:18px;}
h6{font-size:15px;}
.form-control:focus {border-color: #eee;box-shadow: none;}
a,a:hover,a:focus,button,button:focus,button:hover,input:focus{box-shadow:none;color:inherit;outline:none;text-decoration:none;-webkit-transition:all 0.5s ease-in-out 0s;-moz-transition:all 0.5s ease-in-out 0s;-ms-transition:all 0.5s ease-in-out 0s;-o-transition:all 0.5s ease-in-out 0s;transition:all 0.5s ease-in-out 0s;}
.btn-danger.focus, .btn-danger:focus, .btn, .btn-success, .btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus{box-shadow: none;}
.table td .btn-success {background: teal;border-color: teal;}
header{width:100%;padding:0 1rem;background: #6e43e305}
.navbar.navbar-light{padding:0;margin:0;background:none;box-shadow:none;border:0;}
.navbar-nav{align-items:center;-webkit-align-items:center;}
.navbar-light .navbar-nav .nav-item{margin-left:2em;float:none;vertical-align:middle;}
.navbar-light .navbar-nav .nav-link{padding:0;color:#333333;font-size:18px;font-weight:400;border-bottom:2px solid rgba(255, 255, 255, 0);}
.navbar-light .navbar-nav .active .nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link,.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover{color:#6e43e3;border-bottom:2px solid #6e43e3;}
::-webkit-scrollbar{width:5px;}
::-webkit-scrollbar-track{background:#ffffff;}
::-webkit-scrollbar-thumb{background:#888888;}
a,button{cursor:pointer;}
h2{color:#090129;font-size:40px;line-height:1;}
h2 span{font-weight:400;}
.btn-gradient,.btn-primary{color: #fff;padding: 6px 20px;display: inline-block;margin-top: 0;border-radius: 50px;-webkit-border-radius: 50px;-moz-border-radius: 50px;-ms-border-radius: 50px;-o-border-radius: 50px;font-size: 15px;line-height: normal;font-weight: 500;background: rgb(45, 5, 212, 1);overflow: hidden;position: relative;border:2px solid rgb(45, 5, 212, 1);box-shadow:none;min-width:100px;min-height:38px;}
.btn-gradient:hover,.btn-gradient:focus,.btn-primary:hover,.btn-primary:focus{color:#fff;background:rgb(89, 55, 229);box-shadow:none;border:2px solid rgb(89, 55, 229)}
.btn-gradient:after,.btn-primary:after{width:100%;height:20px;content:'';background:#000;opacity:0.15;position:absolute;left:0;-webkit-transition:all 0.6s ease-in-out;-moz-transition:all 0.6s ease-in-out;-ms-transition:all 0.6s ease-in-out;-o-transition:all 0.6s ease-in-out;transition:all 0.6s ease-in-out;-webkit-transform:translate(0px, 50px);-moz-transform:translate(0px, 50px);-ms-transform:translate(0px, 50px);-o-transform:translate(0px, 50px);transform:translate(0px, 50px);}
.btn-gradient:hover:after,.btn-primary:hover:after{transform:translate(0px, -50px);-webkit-transform:translate(0px, -50px);-moz-transform:translate(0px, -50px);-ms-transform:translate(0px, -50px);-o-transform:translate(0px, -50px);}
.btn-outline-primary{color: rgb(45, 5, 212, 1);border:2px solid rgb(45, 5, 212, 1);background: #fff;padding: 6px 20px;display: inline-block;margin-top: 0;border-radius: 50px;-webkit-border-radius: 50px;-moz-border-radius: 50px;-ms-border-radius: 50px;-o-border-radius: 50px;font-size: 15px;line-height: normal;font-weight: 500;overflow: hidden;position: relative;box-shadow:none;min-width:90px;}
.btn-outline-primary:hover,.btn-outline-primary:focus{color:#fff;background: rgb(89, 55, 229);border:2px solid rgb(89, 55, 229);box-shadow: none;}
.btn-outline-primary:after{width:100%;height:20px;content:'';background:#000;opacity:0.15;position:absolute;left:0;-webkit-transition:all 0.6s ease-in-out;-moz-transition:all 0.6s ease-in-out;-ms-transition:all 0.6s ease-in-out;-o-transition:all 0.6s ease-in-out;transition:all 0.6s ease-in-out;-webkit-transform:translate(0px, 50px);-moz-transform:translate(0px, 50px);-ms-transform:translate(0px, 50px);-o-transform:translate(0px, 50px);transform:translate(0px, 50px);}
.btn-outline-primary:hover:after{transform:translate(0px, -50px);-webkit-transform:translate(0px, -50px);-moz-transform:translate(0px, -50px);-ms-transform:translate(0px, -50px);-o-transform:translate(0px, -50px);}
.btn-primary.disabled, .btn-primary:disabled,.btn-outline-primary.disabled, .btn-outline-primary:disabled {color: #fff;background-color: #000000;border-color: #000000;min-width: 100px;opacity: 0.3;}
.btn-success.focus, .btn-success:focus {box-shadow: none;}
.logo img {max-width: 175px;}
.default_sec{padding:2rem 1rem 0;position:relative;}
header .media svg,header .media img,.statusCol .media img {width: 30px;height: 30px;margin-right: 1rem;border-radius: 5px;-webkit-border-radius: 5px;box-shadow: 0 0 5px #ccc;-webkit-box-shadow: 0 0 5px #ccc;}
header .media img, header .media svg {object-fit: cover;}
header .dropdown-toggle {display: block;text-align: left;position: relative;padding-right:1rem;}
header .dropdown-toggle::after{position:absolute;right:0;top:1rem;}
header .media{justify-content:flex-end;align-items: center}
header .media h4{font-size:15px;margin-bottom:0.1rem;letter-spacing: 0;}
header .media h5{font-size:10px;margin-bottom:0;text-transform:uppercase;font-weight:300;color:#777777;letter-spacing: 0;}
header .dropdown{display:inline-block;}
header .dropdown-menu {right: 0;left: auto;min-width: 0;padding: 0.5rem 0 0;border: 0;text-align: center;margin: auto;}
header .dropdown-menu .dropdown-item{padding: 0.5rem 1rem;background: #6e43e3;color: #fff;border-bottom: 1px solid rgba(255,255,255,0.15);text-align: left;font-size: 14px;}
header .dropdown-menu .dropdown-item:hover{background: #272727;color: #fff;}
header .dropdown:hover .dropdown-menu{display:block;}
#contentdata {background: #fff;box-shadow: 5px 5px 50px -35px #777;padding: 2rem;}
#contentdata .form-input{margin-bottom:15px;}
.form-control {min-width: 210px;}
#contentdata .form-control{background:none;border:0;border-bottom:1px solid rgba(231, 231, 231, .7);padding:5px;height:65px;line-height:65px;border-radius:0;box-shadow:none;}
#contentdata textarea.form-control{height:165px;resize:none;}
#contentdata .form-control::-webkit-input-placeholder{text-transform:none;font-size:15px;font-weight:400;color:#9e9e9e;}
#contentdata input[type="submit"]{transition: all 0.6s ease-in-out;color: #fff;padding: 8px 25px;border-radius: 50px;-webkit-border-radius: 50px;-moz-border-radius: 50px;-ms-border-radius: 50px;-o-border-radius: 50px;font-size: 18px;margin-top: 2em;border: 0;line-height: normal;font-weight: 500;background: rgba(45, 5, 212, 1);cursor: pointer;}
#contentdata input[type="submit"]:hover,#contentdata input[type="submit"]:focus{background:rgb(89, 55, 229);}
#contentdata a {font-weight: 600;text-decoration: underline}
.nav-tabs {border-bottom: 0;margin-right:0;max-width: 210px;display: inline-block;vertical-align: top;min-width: 210px;max-height: calc(100vh - 100px);overflow-y: auto;overflow-x: hidden;position: fixed;scroll-behavior: smooth;scrollbar-width: thin;scrollbar-color: #b0b7c4 #f0f1fa}
.nav-tabs::-webkit-scrollbar{width:3px;}
.nav-tabs::-webkit-scrollbar-track, .nav-tabs::-webkit-scrollbar-thumb{background:#fff;}
.nav-tabs:hover::-webkit-scrollbar-thumb{background:#ddd;}
.nav-tabs .nav-item {margin-bottom: 0.5rem;}
.nav-tabs .nav-link {border: 1px solid rgb(89, 55, 229, 0.15);border-top-left-radius: 0;border-top-right-radius: 0;background: #fff;font-weight: 400;padding: 0.8rem;position: relative;color: rgb(89, 55, 229);}
.nav-tabs .nav-link span {display: inline-block;min-width: 38px;}
.nav-tabs .nav-link .angleDown {position:absolute;right:1rem;top:0;bottom:0;margin:auto;display: flex;align-items: center;}
.nav-tabs .active .nav-link, .nav-tabs .nav-link.active,.nav-tabs .nav-link:hover {color: rgb(89, 55, 229);border: 1px solid rgb(89, 55, 229);font-weight: 600;background: rgb(89, 55, 229, 0.05);}
.nav-tabs .nav-link svg {width: 30px;height:auto;margin-right:0.2rem;fill:rgb(89, 55, 229)}
.nav-tabs .active .nav-link svg, .nav-tabs .nav-link.active svg,.nav-tabs .nav-link:hover svg {fill: rgb(45, 5, 212, 1);}
.mainWrapper {display: flex;justify-content: space-between;align-items: flex-start;flex-flow: row wrap;height: calc(100vh - 90px);overflow-y: auto;overflow-x: hidden;scroll-behavior: smooth;scrollbar-width: thin;scrollbar-color: #b0b7c4 #f0f1fa}
.table-responsive {padding: 0;}
.table-responsive::-webkit-scrollbar{width:5px;}
.table-responsive::-webkit-scrollbar-track{background:#ffffff;}
.table-responsive::-webkit-scrollbar-thumb{background:#888888;}
.mainWrapper::-webkit-scrollbar{width:5px;}
.mainWrapper::-webkit-scrollbar-track {background:#ffffff;}
.mainWrapper::-webkit-scrollbar-thumb{background:#888888;}
.tab-content {width: calc(100% - 230px);display: inline-block;vertical-align: top;margin-left: 230px;}
.dateSelect {align-items: flex-start;}
.relative {position: relative;}
.card{margin-bottom:0.2rem;border-radius:0}
.card-header {padding: .75rem 1rem .75rem 1rem;margin-bottom: 0;width: 100%;text-align: left;cursor: pointer;color: rgb(45, 5, 212, 1);background-color: rgba(0,0,0,.03);border-bottom: 1px solid rgba(0,0,0,.125);text-decoration: none;position: relative;display: flex;border: 0;align-items: center;justify-content: space-between;}
.card-header .actions {opacity: 0;}
.card-header:hover .actions {opacity: 1;}
.card-header:after{position:absolute;right:0.5rem;content:'\f107';top:0;bottom:0;margin:auto;font-family:'FontAwesome';line-height:50px;transition:all 0.5s ease-in-out;}
.accordion .show {border-top: 1px solid rgba(0,0,0,.125);}
.accordion>.card:not(:last-of-type) {border-bottom: 1px solid rgba(0,0,0,.125);}
.card-body {padding: 1rem}
.card-body pre {margin:0}
.tab-content>.active {display: block;opacity: 1;}
.acc-step-number {display: none;}
#form .list-group h5 {padding: .75rem 1.25rem;margin-bottom: 0;width:100%;text-align:left;cursor:pointer;color:#272727;background-color: rgba(0,0,0,.03);border-bottom: 0;text-decoration:none;position:relative;line-height:1.5;}
#form .list-group .open h5{color: rgb(45, 5, 212, 1);border-bottom:1px solid rgba(0,0,0,.125);}
#form .list-group h5:after,#form .list-group .open h5:after{position:absolute;right:1rem;top:0;bottom:0;margin:auto;font-family:'FontAwesome';line-height:50px;transition:all 0.5s ease-in-out;}
#form .list-group h5:after{content:'\f067';}
#form .list-group .open h5:after{content:'\f068';}
#form .list-group-item {position: relative;display: block;padding: 0;margin-bottom: 0;}
#form .btn-primary{margin:0 1rem 1rem 0;}
#form .btn-light {margin-left: 1rem;background: #555;color: #fff;padding: 8px 25px;display: inline-block;border-radius: 50px;-webkit-border-radius: 50px;-moz-border-radius: 50px;-ms-border-radius: 50px;-o-border-radius: 50px;font-size: 15px;line-height: normal;font-weight: 500;overflow: hidden;position: relative;}
#form .btn-light:hover,#form .btn-light:focus{color:#fff;background:rgb(89, 55, 229);}
#form .btn-light:after,#form .btn-light:after{width:100%;height:20px;content:'';background:#000;opacity:0.15;position:absolute;left:0;-webkit-transition:all 0.6s ease-in-out;-moz-transition:all 0.6s ease-in-out;-ms-transition:all 0.6s ease-in-out;-o-transition:all 0.6s ease-in-out;transition:all 0.6s ease-in-out;-webkit-transform:translate(0px, 50px);-moz-transform:translate(0px, 50px);-ms-transform:translate(0px, 50px);-o-transform:translate(0px, 50px);transform:translate(0px, 50px);}
#form .btn-light:hover:after,#form .btn-light:hover:after{transform:translate(0px, -50px);-webkit-transform:translate(0px, -50px);-moz-transform:translate(0px, -50px);-ms-transform:translate(0px, -50px);-o-transform:translate(0px, -50px);}
.media svg {width: 32px;height: auto;margin-right: 0.7rem;}
.table {margin-top:1rem;margin-bottom: 0}
.table .btn {padding: 4px 10px;display: inline-block;border-radius: 50px;-webkit-border-radius: 50px;-moz-border-radius: 50px;-ms-border-radius: 50px;-o-border-radius: 50px;font-size: 14px;line-height: normal;font-weight: 500;overflow: hidden;position: relative;box-shadow: none;min-height: 0;min-width: 90px;text-align: center;border: 0;line-height: 22px;}
.table .btn:after{display: none;}
.table tr td,.table tr th{vertical-align:middle;}
.table tr td:first-child {border-right:0;}
.table tr td:last-child {min-width: 100px;width: 5%;border-left: 0;}
.table .media {align-items:center;}
.table tr h4 {margin: 0;font-size: 16px;}
.table tr h4 span {font-size: 10px;display:block;text-transform:uppercase;color:#555555;}
.table-striped tbody tr:nth-of-type(odd) {background-color: rgba(0,0,0,0);}
.table tr th {background: #fcfbff;min-width: 128px;}
.table tr .first-child {min-width:78px;width: 5%;}
.table tr .two-child {min-width:118px;}
.table tr .three-child {min-width:100px;}
.table tr .four-child, .projectTable .table tr .four-child {min-width:235px;}
.table tr .five-child {min-width:118px;}
.table tr .six-child {min-width:138px;}
.table tr .seven-child {min-width:78px;}
.table tr .eight-child {min-width:58px;}
.table tr .auto-child {min-width:170px;}
.table tr td i, .card-header .actions i {width:25px;height:25px;line-height:25px;border-radius:100px;text-align:center;margin:0 0.2rem 0 0;display:inline-block;cursor:pointer;font-size:13px;}
.table tr td .fa-pencil, .card-header .actions .fa-pencil {background:rgb(45, 5, 212, 1);color:#fff;}
.table tr td .fa-trash-o, .card-header .actions .fa-trash-o {background:#c11000;color:#fff;}
.table tr th, .table tr td {border-right: 0;border-top: 0;border-left: 0;}
.table tr th:first-child,.table tr td:first-child {border-left: 1px solid #dee2e6;}
.table tr th,.table tr td {border-right: 1px solid #dee2e6 !important;text-align: left !important;}
.table tr th {border-top: 1px solid #dee2e6;}
.table tr th:last-child,.table tr td:last-child {border-right: 1px solid #dee2e6;}
.teamCol {box-shadow:0 6px 35px -12px rgba(0,0,0,.175);background:#fff;position:relative;overflow:hidden;cursor:pointer}
.teamCol .media {flex-flow:column wrap;justify-content:center;align-items: center;text-align: center;}
.teamCol .actions {text-align: center;display: flex;margin-bottom: 0;flex-flow:column wrap;position: absolute;top: 0;bottom: 0;right: 0;transform:translateX(102%);-webkit-transform:translateX(102%);transition:transform 0.5s ease-in;padding:0.5rem 0.5rem 0 0;}
.teamCol .actions i {width:25px;height:25px;line-height:25px;border-radius:100px;text-align:center;margin:0 0 0.2rem;display:inline-block;cursor:pointer;font-size:15px;background: #272727;color: #fff}
.teamCol .actions .fa-pencil{background:rgb(45, 5, 212, 1);color:#fff;}
.teamCol .actions .fa-times{background:#555555;color:#fff;}
.teamCol .actions i:hover{opacity:0.8;}
.teamCol:hover .actions { transform:translateX(0); -webkit-transform:translateX(0); -moz-transform:translateX(0); -ms-transform:translateX(0); -o-transform:translateX(0); }
.teamCol .media h4 {margin:0;font-size:18px;}
.teamCol .media h4 span {font-size: 12px;margin-bottom: 0;text-transform: uppercase;font-weight: 300;color: #777777;display:block;}
.teamCol .thumbnail img {width: 100px;height:100px;-webkit-border-radius: 100px;border-radius: 100px;margin: 0 0 1rem;cursor: pointer;object-fit: cover;}
.teamCol:hover .thumbnail img {filter: grayscale(0.3)}
.react-fancybox .thumbnail img {max-width: 100% !important;}
.react-fancybox .box{padding: 0 15px;}
.teamCol .badge {position: absolute;left: 0;top: 8px;background: #2d05d4;width: auto;height: auto;line-height: 20px;text-align: center;-webkit-border-radius: 0 5px 5px 0;border-radius: 0 5px 5px 0;color: #ffffff;font-size: 16px;min-width: 50px;}
.settingForm {display: block;box-shadow: 2px 2px 50px -30px #555555;-webkit-box-shadow: 2px 2px 50px -30px #555555;padding: 3rem;background: #fff;}
.uploadPhoto {display: block;text-align: center;color: #2727279e;text-transform: uppercase;font-weight: 500;cursor:pointer;}
.uploadPhoto embed {width:400px;height: 400px;max-width: 100%;max-height: 100%;}
.uploadPhoto img {border-radius: 10px;-webkit-border-radius: 10px;}
.uploadPhoto span {display:block;}
.uploadPhoto svg {width:150px;height:auto;margin-bottom:1rem;}
.modal {padding: 0 1rem !important;}
.modal-body label {display: block;font-weight: 500;margin: 0;}
.modal-body .form-group ul{padding-left:1rem;}
.modal-body .form-group ul li {list-style: disc;margin-bottom: 0.5rem;}
.modal-dialog {max-width:800px;width:100%;display: flex;align-items: center;justify-content: center;height: 100%;margin: auto;}
.modal-body {height: auto;overflow-y: auto;overflow-x: hidden;max-height: calc(100vh - 80px)}
#viewReports .modal-body {height: calc(100vh - 130px);}
.modal-body .btn {margin: 0 0.2rem}
.modal-title {margin-bottom: 0;line-height: normal;font-size: 1.2rem;}
.modal-header {padding: 0.5rem 1rem}
.statusCol {padding:1rem;margin-bottom:1rem;box-shadow:0 6px 35px -20px rgba(0,0,0,.175);-webkit-box-shadow:0 6px 35px -20px rgba(0,0,0,.175);}
.statusCol video, .statusCol iframe, .statusCol embed {width:100%;height:100%;max-width: 100%;max-height: 100%;object-fit: cover;object-position: 0 0;}
.statusCol embed[type="image/jpeg"], .statusCol embed[type="image/png"], .statusCol embed[type="image/gif"] {height: auto;width: auto;}
video:-webkit-full-page-media {width: 100% !important;height: 100% !important;}
.statusCol embed video {position: fixed;width: 100vw;height: 100vh;}
.statusCol .media {margin-bottom:0.6rem;}
.statusCol .media h4 {font-size:14px;font-weight: 500;margin: 0}
.statusCol .media h4 span {display:block;color:#555555;font-size:70%;font-weight:300;}
.statusCol .media i {width:25px;height: 25px;border-radius: 100px;text-align: center;line-height: 25px;font-size: 13px;opacity: 0;}
.statusCol:hover i {opacity: 1;}
.statusCol .media .fa-trash-o {background: #c11000;color: #fff;}
.statusCol .media .fa-pencil {background: #2d05d4;color: #fff;}
.statusCol .media p {font-size:13px;margin: 0}
.statusCol p {font-size: 16px;margin: 0.5rem 0 0;}
.statusCol pre {margin: 0.5rem 0 0;}
pre {overflow: visible;white-space: pre-line;font-size: 16px;}
.commentBox {border-top: 1px solid #eee;margin-top: 1rem;padding-top: 1rem;border-radius: 5px;-webkit-border-radius: 5px;}
.commentBox ul {display:flex;align-items:center;justify-content:flex-end;flex-flow:row wrap;}
.commentBox ul li {margin-left:1rem;color:#999999;cursor:pointer;position: relative;}
.commentBox ul li .likedUsers {background: #fff;padding: 0;box-shadow: 0 0 5px rgb(0 0 0 / 18%);right: 0;position: absolute;top: auto;min-width: 10rem;max-height: 250px;overflow-x: hidden;overflow-y: auto;display: none;bottom: 100%;transform: translateY(0px);-webkit-transform: translateY(0px);-moz-transform: translateY(0px);-ms-transform: translateY(0px);-o-transform: translateY(0px);}
.commentBox ul li .likedUsers p {margin: 0;padding: 0.5rem;font-size: 11px;display: flex;align-items: center;flex-flow: row nowrap;line-height: 12px;border-bottom: 1px solid rgba(0,0,0,.03)}
.commentBox ul li .likedUsers p:last-child {border-bottom: 0}
.commentBox ul li .likedUsers p img, .commentBox ul li .likedUsers p svg {width: 25px;height: 25px;border-radius: 100px;margin-right: .5rem;}
.commentBox ul li:hover .likedUsers {display: block}
.commentBox ul .formCollapse {flex: 0 0 100%;margin: 1rem 0 0 0}
.commentBox li .form-group {margin:0;}
.commentReply .media img,.commentReply .media svg {width: 32px;height: auto;margin-right: 0.7rem;}
.commentReply {max-height: 250px;overflow-x: hidden;overflow-y: auto;}
.commentReply p .comment-delete, .commentReply p .comment-edit {opacity: 0;}
.commentReply p:hover .comment-delete, .commentReply p:hover .comment-edit {opacity: 1;}
.projectList {display: flex;align-items: center;margin-bottom: 1rem;flex-flow: row wrap;}
.projectList li {border: 0;padding: 0;margin-bottom: 0.5rem;flex: 0 0 33%;}
.projectList li i {font-size: 60%;color: #ccc}
.reportForm label {margin: 0;min-width: 0;margin-bottom: 0;font-weight: 500;display: block;font-size: 18px;min-width: 180px;text-align: left;line-height: 20px}
.reportForm {position: relative;z-index: 1;}
#multiselectContainerReact {margin-right: 1rem}
#multiselectContainerReact div span {margin-bottom: 0;padding: 2px 6px;}
.optionListContainer {z-index: 222;}
.reportForm .form-control {height: 42px;margin: 0 1rem}
.reportForm textarea.form-control {height: auto;}
textarea::-webkit-scrollbar{width:0;}
.reportForm .button-group {min-width: 230px;}
.reportForm .form-group {margin: 0;text-align: center;}
.reportForm .btn-primary i {font-size: 80%;}
.shadow-lg {box-shadow: 0 0.2rem 1rem -0.5rem rgba(0,0,0,.175)!important}
.table tr td:last-child span {line-height: 10px;font-size: 88%;font-weight: 600}
.form-group {position: relative;}
.react-datepicker-popper[data-placement^="bottom"] {margin-top: -10px !important;}
.react-datepicker__input-container input[type="text"] {border: 1px solid #eee;border-radius: 0.25rem;padding: 0.375rem 0.5rem;width: 100%}
.table {margin-top: 0}
.task-list {display: flex;flex: 0 0 65%;align-items: center;margin-bottom: 0;}
.task-list .btn-danger {margin: 0 0 0 0.5rem;}
.reportForm .task-list {margin: 0.5rem 0 0;}
.reportForm .task-list:first-child {margin: 0;}
.reportForm .task-list .form-control {margin: 0;width: 85%;min-width: 225px;}
.reportForm .form-check-label {font-weight: 300;font-size: 14px;}
.form-check-inline .form-check-input {width: 17px;height: 17px;}
.checkForm .form-check-inline {display: flex;margin-right: 0;margin-bottom: 0.5rem;align-items: flex-start}
.taskStep .form-group {flex: 1;}
.taskStep label {line-height: 42px}
.timeDiv {flex: 1 1;display: flex;align-items: center;}
.panelToggle{display: none}
.custom-select:focus {border-color: #4d87c5;box-shadow: none;}
.form-inline .custom-select, .form-inline .input-group {width: 100%;}
.form-inline {flex-flow: row nowrap;flex: auto;}
.form-inline label {min-width: 100px;}
.pagination {border-radius: 0;justify-content: center;padding: 0;margin-top: 1rem;}
.pagination li {display: inline-block;padding-left: 0;}
.pagination li {list-style: none;border: 0;margin: 0 0.1rem}
.pagination li a,.pagination li span {position: relative;float: left;padding: 4px 10px;line-height: 1.42857143;text-decoration: none;background: #080015;color: #fff !important;border: 0;margin-left: 0px;}
.pagination li.active a {color: #fff;background: #1348e9;}
.pagination li a:hover {background-color: #1348e9;color: white;}
.pagination li:first-child a,.pagination li:first-child span {margin-left: 0;display: none;}  
.pagination li:last-child a,.pagination li:last-child span {display: none;}
.react-datepicker {min-width: 190px;margin-top: 7px;}
.react-datepicker__month .react-datepicker__month-text {width: 3rem !important;}
.react-datepicker__month-container {width: 100%}
.MuiGrid-spacing-xs-1 {margin: 0 !important;}
.MuiListItem-alignItemsFlexStart {align-items: center !important;justify-content: space-between !important;}
.MuiListItem-alignItemsFlexStart i {width:25px;height: 25px;border-radius: 100px;text-align: center;line-height: 25px;font-size: 13px;opacity: 0;}
.MuiListItem-alignItemsFlexStart .fa-trash-o {background: #c11000;color: #fff;}
.MuiListItem-alignItemsFlexStart:hover i {opacity: 1;}
.MuiTypography-displayBlock {white-space: pre-line;}
.editForm {display: flex;align-items: center;justify-content: space-between;}
.statusCol .media p .editForm~.comment-delete {opacity: 1;}
.projectTable h3 {margin-bottom: 0;min-width: 165px;font-size: 1.5rem;margin-right: 1rem;position: relative;}
sup {top: 0;}
.projectTable h3 sup {position: absolute;font-size: 70%;right: auto;opacity: 0.6;top: -0.3rem;}
.projectTable .table tr td .btn-warning.disabled, .projectTable .table tr td .btn-warning:disabled {color: #ffbf00;background-color: transparent;border-color: transparent;padding: 0;font-weight: 600;min-width: 0;border-radius: 0}
.projectTable .single tbody tr {left: 0 !important;}
.table-bordered thead td, .table-bordered thead th {border-bottom-width: 1px;}
.table tr th, .table tr td {text-align: center;text-transform: capitalize;}
.projectTable .table .btn-group {display: block;height: auto;}
.projectTable .table .btn-group .btn { font-size: 12px; line-height: 22px; margin: 0; padding: 0; border: 0; background: none; display: block; min-width: 0; border-radius: 0; -webkit-border-radius: 0; -moz-border-radius: 0; -ms-border-radius: 0; -o-border-radius: 0; }
.projectTable .table .btn-group .btn span { font-size: 12px; line-height: 22px; margin: 0.15rem 0 0; border: 0; background: #2d17d4; display: block; min-width: 90px; -webkit-border-radius: 50px; -moz-border-radius: 50px; -ms-border-radius: 50px; -o-border-radius: 50px; border-radius: 50px; }
.projectTable .table .btn-group .btn .btn-status-pending, .projectTable .table td .btn-status-pending {background: darkred;}
.projectTable .table .btn-group .btn .btn-status-completed, .projectTable .table td .btn-status-completed {background: darkgreen;}
.projectTable .table .btn-group .btn .btn-status-hold, .projectTable .table td .btn-status-hold {background: #797979;}
.projectTable .table .btn-group .btn .btn-status-progress, .projectTable .table td .btn-status-progress {background: darkorange;}
.projectTable .table td .btn-status-pending, .projectTable .table td .btn-status-completed, .projectTable .table td .btn-status-hold, .projectTable .table td .btn-status-progress {color: #fff;border-radius: 50px;display: inline-block;line-height: 22px !important;padding: 4px 10px;text-align: center;-webkit-border-radius: 50px;-moz-border-radius: 50px;-ms-border-radius: 50px;-o-border-radius: 50px;min-width: 90px;}
.projectTable .dropdown-menu {background: none;border: 0;padding: 0;margin: 0;}
.projectTable .table .dropdown-menu .btn {font-size: 12px;line-height: 18px;margin: 0;border: 0;background: none;display: block;min-width: 90px;border-radius: 0;}
.projectTable .dropdown:hover .dropdown-menu {display: block;}
.table tr th:nth-child(1), .table tr td:nth-child(1) {text-align: left;}
.projectTable .table td .spanLabel {height: 25px;}
.spanLabel { border-radius:50px; -webkit-border-radius:50px; -moz-border-radius:50px; -ms-border-radius:50px; -o-border-radius:50px;}
.single tr th:nth-child(1) {min-width: 75px;}
.modal-body .table tr th:nth-child(1) {min-width: 75px;}
.dl-list {position: relative;}
.modal .react-datepicker-wrapper {width: 100%}
.react-datepicker__input-container input[type="text"] {display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: .375rem .75rem;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.react-autosuggest__container {position: relative;flex: auto}
.react-autosuggest__input {height: 42px;display: block;width: 100%;padding: .375rem .75rem;font-size: 1rem;font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.react-autosuggest__input--focused {outline: none;}  
.react-autosuggest__input--open {border-bottom-left-radius: 0;border-bottom-right-radius: 0;}
.react-autosuggest__suggestions-container {display: none;}
.react-autosuggest__suggestions-container--open {display: block;position: absolute;top: 40px;width: 100%;top:auto;border: 1px solid #ced4da;background-color: #fff;font-size: 14px;border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;z-index: 2;text-align: left;border-top: 0}
.react-autosuggest__suggestions-list {margin: 0;padding: 0;list-style-type: none;}
.react-autosuggest__suggestion {cursor: pointer;padding: 10px;}
header .notificationPop {background: #272727;border: 0;min-width: 0;padding: 0;border-radius: 100px;width: 40px;height: 40px;text-align: center;line-height: 40px;position: relative;overflow: visible;color: #fff;margin-right: 1.2rem;}
.rightSidebar {position: fixed;right: -100%;background: #fcfbfe;top: 0;z-index: 1;width: 280px;max-width:100%;box-shadow: 0 0 40px -30px #000;transform: translateX(100%);-webkit-transform: translateX(100%);-moz-transform: translateX(100%);-ms-transform: translateX(100%);-o-transform: translateX(100%);transition: all 0.7s ease-in-out;-webkit-transition: all 0.7s ease-in-out;-moz-transition: all 0.7s ease-in-out;-ms-transition: all 0.7s ease-in-out;-o-transition: all 0.7s ease-in-out;text-align: left;height: 100vh;clear: left;display: inline-block;overflow-y: auto;overflow-x: hidden;scroll-behavior: smooth;scrollbar-width: thin;scrollbar-color: #b0b7c4 #f0f1fa}
.rightSidebar::-webkit-scrollbar{width:3px;}
.rightSidebar::-webkit-scrollbar-track{background:#f1f1f1;}
.rightSidebar::-webkit-scrollbar-thumb{background: #ffffff;}
.rightSidebar::-webkit-scrollbar-thumb:hover{background:#555;}
.sidebarOpen { right:0; transform:translateX(0%); -webkit-transform:translateX(0%); -moz-transform:translateX(0%); -ms-transform:translateX(0%); -o-transform:translateX(0%); }
.rightSidebar .right {position: sticky;right: 10px;top: 15px;font-size: 1.1rem;cursor: pointer;float: right;background: #f0f0f0;width: 30px;height: 30px;text-align: center;line-height: 30px;border-radius: 100px;-webkit-border-radius: 100px;-moz-border-radius: 100px;-ms-border-radius: 100px;-o-border-radius: 100px;color: #99999980;}
.rightSidebar .right:hover, .rightSidebar .right:focus { color: #999999;}
header .notificationPop:after {display:none}
header .notificationPop:hover,header .notificationPop:focus {border:0;padding:0;box-shadow: none;outline: none;}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {box-shadow: none;}
.notificationPop .badge {position: absolute;right: -9px;top: -4px;background: #2d17d4;width: 22px;height: 22px;line-height: 18px;border-radius: 100px;-webkit-border-radius: 100px;-moz-border-radius: 100px;-ms-border-radius: 100px;-o-border-radius: 100px;color: #ffffff;font-size: 10px;border: 2px solid #fff;letter-spacing: 0;font-family: arial;font-weight: 500;padding: 0;text-align: center;}
.message {text-align:left;padding: 0.5rem 1em;color: #272727;border-bottom: 1px solid rgba(0,0,0,0.15);font-size: 13px;line-height: 17px;}
.unreadMessage {font-weight: 600;}
.message span {display: block;}
.rightSidebar .btn-mark { margin: 1rem 0 0 0.8rem; border: 0; background: #272727; color: #ffffff; font-size: 12px; padding: 0.5rem 0.8rem; border-radius: 2px; -webkit-border-radius: 2px; -moz-border-radius: 2px; -ms-border-radius: 2px; -o-border-radius: 2px;position: sticky;top: 10px;left: auto;}
.rightSidebar .btn-mark:hover,.rightSidebar .btn-mark:focus { background: #777777; color: #ffffff; padding: 0.5rem 0.8rem; }
.rightSidebar .btn-clear {margin: 0;border: 0;color: #272727;font-size: 14px;padding: 12px;display: block;background: none;text-align: center;position: sticky;left: 0;bottom: 0;width: 100%;background: #fcfbfe;top: auto;}
.rightSidebar .btn-clear:hover,.rightSidebar .btn-clear:focus { color: teal;}
.hv-100 {height: 100vh;}
#multiselectContainerReact ._2iA8p44d0WZ-WqRBGcAuEV {min-height: 42px;height: auto;padding: .375rem .75rem;}
.projectTable {overflow-x: hidden;}
.overflowX {overflow: visible;}
[data-rbd-droppable-context-id="1"] {position: relative;}
.status-default-image {margin: auto;display: block;}
.react-switch-bg { height: 20px !important; width:40px !important;}
.react-switch-handle { height: 17px !important; width: 17px !important;}
.css-2b097c-container{ min-width: 250px; margin-right: 0.75rem;}
.multipleFlex {display: flex;align-items: center;flex-flow: row wrap;justify-content: center;}
.multipleFlex * {flex: 0 0 28%;max-width: 28%;margin: 0 0.5rem;}
.react-datepicker-popper[data-placement^="top"] {margin-bottom: 0;transform: none !important;right: 0 !important;left: auto !important;top: 100% !important;}
.react-datepicker-popper[data-placement^="bottom"] { margin-top: 0 !important; transform: none !important; left: auto !important; right: 0 !important; top: 100% !important; -webkit-transform: none !important; -moz-transform: none !important; -ms-transform: none !important; -o-transform: none !important; }
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {bottom: auto;margin-bottom: 0;top: -7px;margin-top: 0;}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {bottom: auto;border-top-color: #aeaeae;top: -8px;transform: rotate(180deg);-webkit-transform: rotate(180deg);-moz-transform: rotate(180deg);-ms-transform: rotate(180deg);-o-transform: rotate(180deg);}
.swal-button.swal-button--confirm { background: #2d05d4; color: #fff; border: 1px solid #2d05d4; }
.swal-button.swal-button--confirm:hover { border: 1px solid #2d05d4; background: #fff; color: #2d05d4; }
.swal-button.swal-button--confirm.swal-button--danger { border: 1px solid #e64942; background: #e64942; color: #fff; }
.swal-button.swal-button--confirm.swal-button--danger:hover { border: 1px solid #e64942; background: #fff; color: #e64942;}
.swal-button--danger:hover { color: #fff; border: 2px solid #e64942; }
.spinner-border { vertical-align:0.875em !important; }
/*-=-=-=-==-=-=Media css-=-==-=-==*/
@media (min-width:1200px) and (max-width:1299px) {
    .taskStep .button-group {min-width: 0;margin-left: auto;margin-top: 0;}
    .task-list {display: flex;flex: 0 0 50%;}
    .reportForm .task-list .form-control {width: 100%;min-width: 170px;}
    .taskStep label {min-width: 140px;}
    .timeStep .button-group {min-width: 0;width: 100%;}
    .timeStep .form-group {flex-wrap: wrap;}
    .timeDiv {flex: 0 0 72%;display: flex;align-items: center;margin-bottom: 0.5rem}
    .card-header .actions {margin-right: 0.6rem;}
    .projectTable h3 {min-width: 185px;}
    .card-header .actions,.commentReply p .comment-delete, .commentReply p .comment-edit, .MuiListItem-alignItemsFlexStart i, .statusCol .media i {opacity: 1;}
    .teamCol .actions {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none;}
    .form-inline .custom-select, .form-inline .input-group {width: 100%;margin: 0.5rem 0}
    .dateSelect, .dateSelect .form-inline .form-control {width: 100%;}
    select#inlineFormCustomMemberSelect {margin: 0}
}
@media (min-width:992px) and (max-width:1199px) {
    .taskStep .button-group {min-width: 0;margin-left: auto;margin-top: 0;}
    .task-list {display: flex;flex: 0 0 50%;}
    .reportForm .task-list .form-control {width: 100%;min-width: 170px;}
    .taskStep label {min-width: 140px;}
    .timeStep .button-group {min-width: 0;width: 100%;}
    .timeStep .form-group {flex-wrap: wrap;}
    .timeDiv {flex: 0 0 72%;display: flex;align-items: center;margin-bottom: 0.5rem}
    .card-header .actions {margin-right: 0.6rem;}
    .projectTable h3 {min-width: 185px;}
    .card-header .actions,.commentReply p .comment-delete, .commentReply p .comment-edit, .MuiListItem-alignItemsFlexStart i, .statusCol .media i {opacity: 1;}
    .teamCol .actions {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none;}
    .form-inline .custom-select, .form-inline .input-group {width: 100%;margin: 0}
    .dateSelect, .dateSelect .form-inline .form-control {width: 100%;}
    select#inlineFormCustomMemberSelect {margin: 0}
}
@media (min-width:768px) and (max-width:991px) {
    .reportForm .form-group {flex-wrap: wrap;}
    .reportForm .form-control {margin: 0.5rem 0 0;}
    .reportForm .button-group {min-width: 0;width: 100%;margin-top: 10px}
    .timeDiv {flex: 0 0 100%;}
    .timeDiv span {margin: 0 0.5rem;}
    .task-list {flex: 0 0 100%;}
    .projectTable h3 {min-width: 190px;}
    .form-inline .custom-select, .form-inline .input-group {width: 100%;margin: 0.5rem 0}
    .form-inline {flex: 0 0 100%;}
    .dateSelect, .dateSelect .form-inline .form-control {width: 100%;}
    .dateSelect .form-inline .custom-select, .dateSelect .form-inline .input-group {width: 100%;margin: 0}
    .dateSelect .form-inline {flex: 1;}
    .contact_sec form h2 {font-size: 32px;}
    .card-header .actions,.commentReply p .comment-delete, .commentReply p .comment-edit, .MuiListItem-alignItemsFlexStart i, .statusCol .media i {opacity: 1;}
    .teamCol .actions {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none;}
    .card-header .actions {margin-right: 0.6rem;}
    header {padding: 0;}
    .default_sec {padding: 2rem 0 0}
    #multiselectContainerReact {margin-right: 0;}
    .projectTable h3 {margin-bottom: 0.5rem;}
    .reportForm .task-list .form-control {min-width: 0;}
}
@media (max-width:767px){
    .navbar-collapse{background:#fff;position:absolute;top:50px;width:100%;z-index:888888;right:0;}
    .navbar-collapse.show{right:0;}
    .navbar-nav{height:auto;background:#fff;}
    header{padding: 0;}
    .navbar-light .navbar-toggler{color:#fff;border-color:#fff;margin-left:auto;background:#fff;}
    .navbar-light .navbar-nav .nav-item{width:100%;margin:0;padding:0;}
    .navbar-light .navbar-nav .nav-link{color:#000;padding:15px 25px;}
    .navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link,.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover{color:#6e43e3;border-bottom:2px solid rgb(117, 70, 226, 0.2);}
    .contact_sec .addressBlock {margin-bottom: 4rem;}
    .default_sec{padding: 2rem 0 0}
    .reportForm .form-group {flex-wrap: wrap;}
    .reportForm .form-control {margin: 0.5rem 0;}
    .reportForm .button-group {min-width: 0;width: 100%;margin-top: 0.5rem;}
    .timeDiv {flex: 0 0 100%;}
    .timeDiv span {margin: 0 0.5rem;}
    .task-list {flex: 0 0 100%;}
    .logo {display: flex;justify-content: flex-start;align-items: center;}
    .contact_sec .container {max-width: 100%;}
    .contact_sec form h2 {font-size: 30px;line-height: normal}
    .contact_sec .loginImage{display:none}
    .card-header .actions,.commentReply p .comment-delete, .commentReply p .comment-edit, .MuiListItem-alignItemsFlexStart i, .statusCol .media i {opacity: 1;}
    .card-header .actions {position: absolute;right: 0.2rem;}
    .card-header:after {right: auto;left: 0.5rem;line-height: 40px;}
    .card-header {padding: .75rem 4rem .75rem 1.5rem;line-height: 17px;}
    #multiselectContainerReact {margin-right: 0;margin-bottom: 0.5rem}
    .form-inline .custom-select, .form-inline .input-group {width: 100%;}
    .dateSelect, .dateSelect .form-inline .form-control {width: 100%;}
    .react-autosuggest__container {margin-bottom: 0.5rem}
    .form-inline {margin: 0.5rem 0;}
    .react-datepicker-wrapper {display: block;}
    .table tr .two-child {min-width: 120px;}
    .teamCol .actions {transform: none; -webkit-transform: none; -moz-transform: none; -ms-transform: none; -o-transform: none;}
    .reportForm label {margin-bottom: 0.5rem;}
    .reportForm .task-list .form-control {min-width: 0;}
    .form-inline label {min-width: 0;margin-right: 1rem;}
    .react-datepicker-popper[data-placement^="top"] {right: auto !important;left: 0 !important;}
    .react-datepicker-popper[data-placement^="bottom"] { left: 0 !important; right: auto !important;}
}
@media (max-width:575px){
    .logo img {width: auto;max-width: 120px;}
    .logo a {display: block;width: 35px;overflow: hidden;}
    header{padding:0.3rem 0;}
    header .dropdown-toggle {padding-right: 0.5rem;}
    header .dropdown-toggle::after {right: -0.3rem;top: 0.7rem;}
    .navbar-collapse{background:#fff;position:absolute;top:5px;width:100%;z-index:888888;right:0;}
    .navbar-collapse.show{right:0;}
    .navbar-nav{height:auto;background:#fff;}
    .navbar-light .navbar-toggler{color:#fff;border-color:#fff;margin-left:auto;background:#fff;position:absolute;right:0;top:-55px;}
    .navbar-light .navbar-nav .nav-item{width:100%;margin:0;padding:0;}
    .navbar-light .navbar-nav .nav-link{color:#000;padding:15px 25px;}
    .navbar-light .navbar-nav .active>.nav-link,.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .nav-link.show,.navbar-light .navbar-nav .show>.nav-link,.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover{color:#6e43e3;border-bottom:2px solid rgb(117, 70, 226, 0.2);}
    .menuOpen { transform: translateX(-100%); -webkit-transform: translateX(-100%); transition: all 0.3s ease-in-out; -webkit-transition: all 0.3s ease-in-out; -moz-transform: translateX(-100%); -ms-transform: translateX(-100%); -o-transform: translateX(-100%); }
    .menuClose {transform: translateX(0%);-webkit-transform: translateX(0%);transition: all 0.7s ease-in-out;-webkit-transition: all 0.7s ease-in-out}
    .panelToggle {display: inline-block;background: none;color: #2d05d4;padding: 4px 8px 2px;margin-right: 0.5rem;border: 1px solid #2d05d4;box-shadow: none;cursor: pointer;font-size: 17px;}
    .panelToggle:focus,.panelToggle:hover{background: #2d05d4;box-shadow: none;outline: none;color: #fff}
    .mainWrapper {max-height: 100%;padding: 2rem 0;height: 100vh;}
    .nav-tabs {min-width:100%;max-width:100%;margin-right: 0;margin-bottom: 0;position: absolute;left: 0;top: 0;background: #fff;padding: 0.5rem;z-index: 222;min-height: 100%;max-height: 100vh;}
    .tab-content {width:100%;margin-left: 0;}
    .p-4 {padding:1rem!important}
    .reportForm .form-control {margin: 0.5rem 0;}
    .projectTable .table tr th:first-child, .modal-body .table tr th:first-child {min-width: 75px;}
    .dateSelect {width: 100%; margin-top: 0.7rem;}
    .reportForm .custom-select {margin: 0.5rem 0;}
    .contact_sec form h2 {font-size: 25px;line-height: normal}
    #contentdata {padding: 1rem;}
    .default_sec {padding: 0;}
    .reportForm .task-list:first-child, .reportForm .task-list {margin: 0 0 0.5rem;}
    .uploadPhoto embed {width: auto;height: auto;}
    .projectTable h3 {margin-right: 0;}
    .overflowX, .projectTable {overflow-x: visible;}
}
@media (max-width:415px){
    header .media h4 {font-size: 12px;margin-bottom: 0.2rem;}
    header .media h5 {font-size: 10px;}
    header .dropdown-toggle {padding-right: 0.5rem;}
    header .dropdown-toggle::after {right: -0.3rem;top: 0.7rem;}
    header .notificationPop {width: 36px;height: 36px;line-height: 36px;margin-right: 1.5rem;}
    .notificationPop .badge {line-height: 20px;right: -10px;top: -5px;}
}
@media (max-width:360px) {
    header .media h4 , header .media h5 {display: none;}
}